import React from 'react'
import { Layout, UserContext } from '../../components/Layout'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { getQueryVariable, handleErrorFromAPI, loginRedirect } from '../../utils'
import { ErrorNotification } from '../../components/Notification'
import { navigate } from 'gatsby'
import {
  ChecklistCategoryPageResponse,
  getChecklistCategoryData,
  ChecklistItem,
  submitResults,
  StoredAnswersObject,
  retrieveResults
} from '../../api/checklists'
import { WindowLocation } from '@reach/router'
import { HorizontalLine } from '../../components/Line'
import { LabelLarge, ParagraphLarge } from 'baseui/typography'
import { MODE, ButtonGroup } from 'baseui/button-group'
import { Button as BaseButton, ButtonProps } from 'baseui/button'
import { Button } from '../../components/Button'
import constants from '../../constants'
import { useStyletron } from 'baseui'
import { ourColors } from '../../components/Colors'
import { expandBorderStyles } from 'baseui/styles'
import { Title } from '../../components/Title'

interface ScaleButtonProps extends ButtonProps {
  color: string
}

const ScaleButton = React.forwardRef<HTMLButtonElement, ScaleButtonProps>(
  ({ color, children, ...props }, ref) => {
    const [css] = useStyletron()
    const coloredCircle = (
      <span
        className={css({
          height: '15px',
          width: '15px',
          backgroundColor: color,
          marginRight: '3px',
          borderRadius: '50%'
        })}
      ></span>
    )
    return (
      <BaseButton
        {...props}
        ref={ref}
        type="button"
        overrides={{
          BaseButton: {
            style: () => ({
              backgroundColor: props.isSelected ? color : ourColors.white,
              color: props.isSelected ? ourColors.white : ourColors.copyGrey,
              height: '37px',
              width: '100%',
              marginRight: '7px',
              ...expandBorderStyles({
                borderColor: ourColors.interfaceGrey,
                borderWidth: '1px',
                borderStyle: 'solid'
              }),
              ':hover': {
                backgroundColor: props.isSelected ? color : ourColors.lightGrey,
                color: props.isSelected ? ourColors.white : ourColors.copyGrey
              },
              ':active': {
                backgroundColor: ourColors.lightGrey,
                color: ourColors.copyGrey
              }
            })
          }
        }}
      >
        {props.isSelected ? null : coloredCircle}
        {children}
      </BaseButton>
    )
  }
)

ScaleButton.displayName = 'ScaleButton'

interface ChecklistItemProps {
  item: ChecklistItem
  selected: number
  answerSetter: (index: number, itemId: string) => void
}

export const ChecklistItemPanel = ({ item, selected, answerSetter }: ChecklistItemProps) => {
  const [detailsExpanded, setDetailsExpanded] = React.useState(false)
  const [css] = useStyletron()
  return (
    <Cell span={[4, 6]} align={ALIGNMENT.center}>
      <div
        className={css({
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: ourColors.interfaceGrey,
          borderRadius: '5px',
          marginBottom: '16px',
          paddingLeft: '14px',
          paddingRight: '11px',
          paddingBottom: '16px',
          paddingTop: '22px'
        })}
      >
        <LabelLarge display="inline-block">{item.value.item_name}</LabelLarge>
        <button
          type="button"
          onClick={() => setDetailsExpanded(!detailsExpanded)}
          className={css({
            marginBottom: '22px',
            border: 'none',
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            paddingLeft: '20px',
            cursor: 'pointer',
            ':focus': {
              outlineStyle: 'none'
            }
          })}
        >
          <ParagraphLarge marginBottom={0}>{detailsExpanded ? 'Close' : 'Details'}</ParagraphLarge>
        </button>
        <ParagraphLarge display={detailsExpanded ? 'block' : 'none'}>
          {item.value.details}
        </ParagraphLarge>
        <HorizontalLine
          className={css({
            marginBottom: '14px',
            marginRight: '3px'
          })}
        />
        <ParagraphLarge marginBottom="14px">Assess the Risk</ParagraphLarge>
        <ButtonGroup
          mode={MODE.radio}
          selected={selected}
          onClick={(_event, index) => {
            answerSetter(index, item.id)
          }}
        >
          <ScaleButton color={ourColors.scaleRed}>High</ScaleButton>
          <ScaleButton color={ourColors.scaleAmber}>Reduced</ScaleButton>
          <ScaleButton color={ourColors.scaleGreen}>Low</ScaleButton>
        </ButtonGroup>
      </div>
    </Cell>
  )
}

interface AnswersObject {
  [key: string]: number
}

interface ChecklistProps {
  items: ChecklistItem[]
  planId: string
  categoryId: string
  scale: string[]
}
export const Checklist = ({ items, planId, categoryId, scale }: ChecklistProps) => {
  const { token, setUserContext } = React.useContext(UserContext)
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)
  const [answers, setAnswers] = React.useState<AnswersObject>({})

  const answerSetter = (index: number, itemId: string) => {
    setAnswers({
      ...answers,
      [itemId]: index
    })
  }

  React.useEffect(() => {
    if (token && planId && categoryId) {
      retrieveResults(token, planId, categoryId)
        .then((storedAnswers: StoredAnswersObject) => {
          const mappedAnswers: AnswersObject = {}
          items.forEach(item => {
            if (Object.prototype.hasOwnProperty.call(storedAnswers, item.id)) {
              mappedAnswers[item.id] = scale.indexOf(storedAnswers[item.id])
            }
          })
          setAnswers(mappedAnswers)
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    }
  }, [token, planId, categoryId, setUserContext, scale, items])

  const submitChecklist = (event: React.FormEvent) => {
    const submittedAnswers: StoredAnswersObject = {}
    event.preventDefault()

    for (const itemId in answers) {
      submittedAnswers[itemId] = scale[answers[itemId]]
    }

    if (token) {
      submitResults(token, planId, categoryId, submittedAnswers).then(
        () => navigate(`/checklists/plan?planId=${planId}`),
        () => setLoadingErrorActive(true)
      )
    }

    return false
  }

  const panels = items.map(item => (
    <ChecklistItemPanel
      item={item}
      selected={answers[item.id]}
      answerSetter={answerSetter}
      key={`panel-${item.id}`}
    />
  ))

  const submitErrorMessage = (
    <>
      There was an error saving your checklist. <br />
      If the problem persists, please contact {constants.supportEmail}
    </>
  )

  return (
    <form onSubmit={submitChecklist}>
      <Grid>
        {panels}
        <Cell span={[4, 6]}>
          <ErrorNotification hasError={loadingErrorActive} errorMessage={submitErrorMessage} />
        </Cell>
        <Cell span={[2, 3]}>
          <Button
            kind="secondary"
            type="submit"
            overrides={{
              BaseButton: {
                style: {
                  width: '100%',
                  maxWidth: '100%',
                  marginTop: '16px'
                }
              }
            }}
          >
            Save
          </Button>
        </Cell>
        <Cell span={[2, 3]}>
          <Button
            kind="tertiary"
            type="button"
            onClick={() => navigate(`/checklists/plan?planId=${planId}`)}
            overrides={{
              BaseButton: {
                style: {
                  width: '100%',
                  maxWidth: '100%',
                  marginTop: '16px'
                }
              }
            }}
          >
            Cancel
          </Button>
        </Cell>
      </Grid>
    </form>
  )
}

interface CategoryPageProps {
  location: WindowLocation
}
export const CategoryPage = (props: CategoryPageProps) => {
  const { token, setUserContext } = React.useContext(UserContext)
  const [pageTitle, setPageTitle] = React.useState('')
  const [pageDetails, setPageDetails] = React.useState('')
  const categoryId = getQueryVariable('categoryId', props.location.search)
  const planId = getQueryVariable('planId', props.location.search)
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)
  const [categoryItems, setCategoryItems] = React.useState<ChecklistItem[] | null>(null)
  const scaleArray = ['High', 'Reduced', 'Low']

  React.useEffect(() => {
    if (!token) {
      loginRedirect()
    }

    if (!categoryId || !planId) {
      navigate('/404', { replace: true })
    }

    if (token && categoryId) {
      getChecklistCategoryData(token, categoryId)
        .then((response: ChecklistCategoryPageResponse) => {
          if (response.meta.total_count > 0) {
            const responsePage = response.items[0]
            setPageTitle(responsePage.title)
            setPageDetails(responsePage.details)
            setCategoryItems(responsePage.items)
          } else {
            navigate('/404', { replace: true })
          }
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    }
  }, [token, planId, categoryId, setUserContext])

  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <ErrorNotification hasError={loadingErrorActive} />
        </Cell>
      </Grid>
      {pageTitle && pageDetails && <Title title={pageTitle} initialParagraph={pageDetails} />}
      {categoryItems && planId && categoryId && (
        <Checklist
          items={categoryItems}
          planId={planId}
          categoryId={categoryId}
          scale={scaleArray}
        />
      )}
    </>
  )
}

interface LayoutCategoryPageProps {
  location: WindowLocation
}
const LayoutCategoryPage = (props: LayoutCategoryPageProps) => {
  return (
    <Layout>
      <CategoryPage location={props.location} />
    </Layout>
  )
}

export default LayoutCategoryPage
