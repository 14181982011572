import { HeadingLevel } from 'baseui/heading'
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid'
import { ParagraphLarge } from 'baseui/typography'
import React from 'react'
import { Heading } from './Heading'
import { HorizontalLine } from './Line'

interface TitleProps {
  title: string
  initialParagraph: string
}

export const Title = ({ title, initialParagraph }: TitleProps) => {
  return (
    <>
      <Grid align={ALIGNMENT.center}>
        <Cell span={[4, 6]}>
          <HeadingLevel>
            <Heading data-testid="pageTitle">{title}</Heading>
          </HeadingLevel>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 6]}>
          <HorizontalLine />
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 6]}>
          <ParagraphLarge>{initialParagraph}</ParagraphLarge>
        </Cell>
      </Grid>
    </>
  )
}
